<template>
  <v-container>
    <BaseScreenHeader
      title="My Profile"
      screenInfo="PRO-001"
      width="90%"
      :showBack="false"
      :refresh="true"
      @refresh="refreshPage"
    />

    <v-tabs
      active-class="progressActive charcoal--text"
      class="paper--text"
      v-model="tab"
      v-if="loaded"
      centered
      dark
      icons-and-text
      show-arrows
      background-color="charcoal"
      grow
    >
      <v-tabs-slider color="progressActive" />
      <v-tab class="silverLink" href="#public">
        Profile
        <v-icon>mdi-clipboard-account-outline</v-icon>
      </v-tab>
      <v-tab class="silverLink" href="#preferences">
        Preferences
        <v-icon>mdi-tune</v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="charcoal">
      <v-tab-item key="public" value="public">
        <template>
          <v-card-actions class="mt-2">
            <v-divider class="charcoal lighten-1" />
            <span class="mx-2 title silver--text">
              Basic Information
            </span>
            <v-divider class="charcoal lighten-1" />
          </v-card-actions>
          <v-card-actions class="py-0 ">
            <v-avatar
              v-if="loaded"
              :title="profile.display_name"
              color="progressActive"
              :size="isPhone ? 100 : 150"
              tile
            >
              <v-img
                v-if="profile.picture_cdn != null"
                :alt="profile.display_name"
                :src="resizedName(profile.picture_cdn, '150')"
                contain
                :width="isPhone ? 100 : 150"
              />
              <span
                :class="textSizeLarge + ' progressActive charcoal--text '"
                v-else
              >
                {{ initials(profile.display_name) }}
              </span>
            </v-avatar>
            <v-spacer />
          </v-card-actions>
          <v-card-actions class="pa-0 pr-2">
            <v-btn
              dark
              v-if="profile.profile_pic == null"
              text
              @click="$router.push({ name: 'profilephoto' })"
            >
              Change
            </v-btn>
            <v-spacer />
            <v-btn
              class=""
              text
              color="progressActive"
              :to="'/profile/' + profile.id"
            >
              Preview
            </v-btn>
            <BaseActionButton
              text
              class=" charcoal paper--text mr-n2"
              :disabled="$v.$anyError"
              @clickedThis="saveProfile"
              :style="charcoalTileMenu"
            />
          </v-card-actions>
          <v-card-text class="px-2">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  dark
                  v-model="profile.display_name"
                  class="enlarged-input-small"
                  ref="display_name"
                  :counter="30"
                  label="Display Name"
                  prepend-icon="mdi-tag-text"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  dark
                  v-model="profile.tagline"
                  :error-messages="taglineErrors"
                  class="enlarged-input-small"
                  ref="tagline"
                  :counter="50"
                  label="Your Tagline"
                  prepend-icon="mdi-tag"
                  @input="$v.profile.tagline.$touch()"
                  @blur="$v.profile.tagline.$touch()"
                />
              </v-col>
            </v-row>
            <v-card-text class="px-0">
              <span class="silver--text title">About Me</span>
              <v-spacer />
              <tiptap-vuetify
                class="ma-0 pa-0"
                dark
                v-model="profile.about"
                ref="about"
                min-height="200"
                :extensions="extensions"
                placeholder="Write information about yourself that you want to share with others. This information will be public."
              />
            </v-card-text>
            <v-row dense>
              <v-col class="mt-2 silver--text title" cols="12">
                Display Statistics
                <v-divider class="charcoal lighten-1" />
              </v-col>
              <v-col cols="12" md="4"
                >>
                <v-switch
                  class="pa-0 ma-0"
                  dark
                  color="accent"
                  ref="lifting_statistics"
                  false-value="No"
                  true-value="Yes"
                  label="Show Lifting Statistics on Profile?"
                  v-model="profile.show_lifting_stats"
                  >Show Lifting Statistics?</v-switch
                >
              </v-col>
              <v-col cols="12" md="4">
                <v-switch
                  class="pa-0 ma-0"
                  dark
                  color="accent"
                  ref="activity_statistics"
                  false-value="No"
                  true-value="Yes"
                  label="Show Activity Statistics on Profile?"
                  v-model="profile.show_activity_stats"
                  >Show Activity Statistics?</v-switch
                >
              </v-col>
              <v-col cols="12" md="4">
                <v-switch
                  class="pa-0 ma-0"
                  dark
                  color="accent"
                  ref="basic_statistics"
                  false-value="No"
                  true-value="Yes"
                  label="Show Basic Statistics on Profile?"
                  v-model="profile.show_basic_stats"
                  >Show Basic Statistics?</v-switch
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col class="silver--text title" cols="12">
                Social
                <v-divider class="charcoal lighten-1" />
              </v-col>
              <v-col cols="12" md="8">
                <v-text-field
                  dark
                  v-model="profile.website"
                  :error-messages="websiteErrors"
                  ref="website"
                  :counter="100"
                  label="Your Website"
                  prepend-icon="mdi-earth"
                  @blur="$v.profile.website.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="8">
                <v-text-field
                  dark
                  v-model="profile.instagram"
                  :error-messages="instagramErrors"
                  ref="instagram"
                  :counter="100"
                  label="Instagram"
                  prepend-icon="mdi-instagram"
                  @blur="$v.profile.instagram.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="8">
                <v-text-field
                  dark
                  v-model="profile.facebook"
                  :error-messages="facebookErrors"
                  ref="facebook"
                  :counter="100"
                  label="Facebook"
                  prepend-icon="mdi-facebook"
                  @blur="$v.profile.facebook.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="8">
                <v-text-field
                  dark
                  v-model="profile.linkedin"
                  :error-messages="linkedinErrors"
                  ref="linkedin"
                  :counter="100"
                  label="Linkedin"
                  prepend-icon="mdi-linkedin"
                  @blur="$v.profile.linkedin.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="8">
                <v-text-field
                  dark
                  v-model="profile.youtube"
                  :error-messages="youtubeErrors"
                  ref="youtube"
                  :counter="100"
                  label="Youtube"
                  prepend-icon="mdi-youtube"
                  @blur="$v.profile.youtube.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="8">
                <v-text-field
                  dark
                  v-model="profile.snapchat"
                  :error-messages="snapchatErrors"
                  ref="snapchat"
                  :counter="14"
                  label="Snapchat"
                  prepend-icon="mdi-snapchat"
                  @blur="$v.profile.snapchat.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="8">
                <v-text-field
                  dark
                  v-model="profile.twitter"
                  :error-messages="twitterErrors"
                  ref="twitter"
                  :counter="16"
                  label="Twitter"
                  prepend-icon="mdi-twitter"
                  @blur="$v.profile.twitter.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="8">
                <v-text-field
                  dark
                  v-model="profile.tiktok"
                  :error-messages="tiktokErrors"
                  ref="tiktok"
                  :counter="16"
                  label="Tiktok"
                  prepend-icon="mdi-alpha-k-circle"
                  @blur="$v.profile.tiktok.$touch()"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <BaseActionButton
              text
              class="paper--text mr-n2"
              :style="charcoalTileMenu"
              :disabled="$v.$anyError"
              @clickedThis="saveProfile"
            />
          </v-card-actions>
        </template>
      </v-tab-item>
      <v-tab-item key="preferences" value="preferences">
        <template>
          <v-card-actions class="mt-2 pb-0">
            <v-divider class="charcoal lighten-1" />
            <span class="mx-2 silver--text title">
              Basic Preferences
            </span>
            <v-divider class="charcoal lighten-1" />
          </v-card-actions>
          <v-card-text class="px-2 py-0">
            <v-row dense>
              <v-col cols="12">
                <v-select
                  dark
                  filled
                  color="success"
                  class="mt-5 "
                  label="Timezone"
                  v-model="tz"
                  prepend-inner-icon="mdi-map-clock"
                  :items="timezonesList"
                  item-text="name"
                  item-value="name"
                />
              </v-col>
            </v-row>
            <v-card
              class="pa-2 mb-2 charcoal"
              rounded
              v-for="p in preferences"
              :key="p.preferenceid"
              :style="charcoalTileMenu"
            >
              <v-card-title :class="textSizeSmall + ' paper--text  pa-0'">
                {{ p.type }}
              </v-card-title>
              <v-card-text :class="textSizeXSmall + ' pa-0 silver--text'">
                {{ p.description }}
              </v-card-text>
              <v-card-actions class="py-0">
                <template v-if="p.ui == 'yesno'">
                  <v-switch
                    dark
                    color="accent"
                    false-value="No"
                    true-value="Yes"
                    ref="preference.code"
                    :label="p.name"
                    v-model="p.value"
                    @change="savePreferences"
                  />
                </template>
              </v-card-actions>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <BaseActionButton
              :style="charcoalTileMenu"
              text
              class="paper--text"
              v-bind="$attrs"
              :disabled="$v.$anyError"
              @clickedThis="savePreferences"
            />
          </v-card-actions>
        </template>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  HardBreak,
  HorizontalRule
} from 'tiptap-vuetify'
import { validationMixin } from 'vuelidate'
import { required, url, helpers } from 'vuelidate/lib/validators'
export const instagramRegex = helpers.regex(
  'instagramRegex',
  /^\s*(http(s)?:\/\/)?([\w]+\.)?instagram\.com\/[a-z\d-_/]{1,255}\s*$/i
)
export const facebookRegex = helpers.regex(
  'facebookRegex',
  /^\s*(http(s)?:\/\/)?([\w]+\.)?facebook\.com\/[a-z\d-_/]{1,255}\s*$/i
)
export const linkedinRegex = helpers.regex(
  'linkedinRegex',
  /^\s*(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/[a-z\d-_/]{1,255}\s*$/i
)

export const youtubeRegex = helpers.regex(
  'youtubeRegex',
  /^\s*(http(s)?:\/\/)?([\w]+\.)?youtube\.com\/[a-z\d-_/]{1,255}\s*$/i
)

export const snapchatRegex = helpers.regex(
  'snapchatRegex',
  /^[a-zA-Z][\w-_.]{1,13}[\w]$/
)

export const twitterRegex = helpers.regex('twitterRegex', /^@?(\w){1,15}$/)
export const tiktokRegex = helpers.regex(
  'tiktokRegex',
  /^\s*(http(s)?:\/\/)?([\w]+\.)?tiktok\.com\/[a-z\d-_/]{1,255}\s*$/i
)

export default {
  components: { TiptapVuetify },
  mixins: [util, validationMixin],
  name: 'ManagePref',
  props: {
    show: {
      type: Boolean
    }
  },

  validations: {
    profile: {
      tagline: {
        required
      },
      website: {
        url
      },
      instagram: {
        instagramRegex
      },
      facebook: {
        facebookRegex
      },
      linkedin: {
        linkedinRegex
      },
      youtube: {
        youtubeRegex
      },
      snapchat: {
        snapchatRegex
      },
      twitter: {
        twitterRegex
      },
      tiktok: {
        twitterRegex
      }
    }
  },
  data() {
    return {
      timezonesList: [],
      preferences: [],
      tz: '',
      loaded: false,
      profile: {},
      timeout: 1000,
      tab: null,
      extensions: [
        Paragraph,
        Bold,
        Italic,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3, 4, 5, 6]
            }
          }
        ],
        HorizontalRule,
        Link,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak
      ]
    }
  },
  beforeMount() {
    this.refreshPage()
  },
  computed: {
    ...appConfig,
    taglineErrors() {
      const errors = []
      if (!this.$v.profile.tagline.$dirty) return errors
      !this.$v.profile.tagline.required && errors.push('Tagline is required.')
      return errors
    },
    websiteErrors() {
      const errors = []
      if (!this.$v.profile.website.$dirty) return errors
      !this.$v.profile.website.url && errors.push('This is not a valid url.')
      return errors
    },
    instagramErrors() {
      const errors = []
      if (!this.$v.profile.instagram.$dirty || this.profile.instagram == '')
        return errors
      !this.$v.profile.instagram.instagramRegex &&
        errors.push('This is not a valid Instagram url.')
      return errors
    },
    facebookErrors() {
      const errors = []
      if (!this.$v.profile.facebook.$dirty || this.profile.facebook == '')
        return errors
      !this.$v.profile.facebook.facebookRegex &&
        errors.push('This is not a valid Facebook url.')
      return errors
    },
    linkedinErrors() {
      const errors = []
      if (!this.$v.profile.linkedin.$dirty || this.profile.linkedin == '')
        return errors
      !this.$v.profile.linkedin.linkedinRegex &&
        errors.push('This is not a valid Linkedin url.')
      return errors
    },
    youtubeErrors() {
      const errors = []
      if (!this.$v.profile.youtube.$dirty || this.profile.youtube == '')
        return errors
      !this.$v.profile.youtube.youtubeRegex &&
        errors.push('This is not a valid Youtube url.')
      return errors
    },
    snapchatErrors() {
      const errors = []
      if (!this.$v.profile.snapchat.$dirty || this.profile.snapchat == '')
        return errors
      !this.$v.profile.snapchat.snapchatRegex &&
        errors.push('This is not a valid Snapchat username.')
      return errors
    },
    twitterErrors() {
      const errors = []
      if (!this.$v.profile.twitter.$dirty || this.profile.twitter == '')
        return errors
      !this.$v.profile.twitter.twitterRegex &&
        errors.push('This is not a valid twitter id.')
      return errors
    },
    tiktokErrors() {
      const errors = []
      if (!this.$v.profile.tiktok.$dirty || this.profile.tiktok == '')
        return errors
      !this.$v.profile.tiktok.tiktokRegex &&
        errors.push('This is not a valid tiktok profile.')
      return errors
    }
  },

  methods: {
    refreshPage() {
      this.loadUserTimezone()
      this.loadTimezones()
      this.loadProfile()
    },
    savePreferences() {
      return axios
        .post(this.baseURL + '/user/preferences', {
          preferences: this.preferences,
          timezone: this.tz
        })
        .then(response => {
          if (response.status == 200) {
            let metric = this.preferences.find(
              element => element.code == 'USE_METRIC'
            ).value
            this.$store.dispatch('session/setUnit', metric)
            this.$v.$reset()
            this.toast('Preferences Saved..')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    saveProfile() {
      return axios
        .put(this.baseURL + '/user/profile', {
          profile: this.profile
        })
        .then(response => {
          if (response.status == 200) {
            this.$v.$reset()
            this.toast('Profile Saved..')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadProfile() {
      return axios.get(this.baseURL + '/user/profile', {}).then(response => {
        if (response.status == 200) {
          this.profile = response.data.data
          this.loadPreferences()
          this.loaded = true
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
          return
        }
      })
    },
    loadUserTimezone() {
      return axios.get(this.baseURL + '/user/timezone', {}).then(response => {
        if (response.status == 200) {
          this.tz = response.data.data.timezone
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
          return
        }
      })
    },
    loadTimezones() {
      return axios
        .get(this.$store.state.config.baseURL + '/util/tz/all')
        .then(response => {
          if (response.status == 200) {
            this.timezonesList = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadPreferences() {
      return axios.get(this.baseURL + '/user/preferences').then(response => {
        if (response.status == 200) {
          this.preferences = response.data.data
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
          return
        }
      })
    }
  }
}
</script>
