var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('BaseScreenHeader',{attrs:{"title":"Add Profile Photo","screenInfo":"PRO-003","width":"90%","showBack":true}}),(!_vm.loaded)?_c('BaseLoading',{staticClass:"mt-2",attrs:{"label":"Updating your profile picture..."}}):_c('v-card',{staticClass:"charcoal charcoalTile pa-2 mx-2 d-flex flex-column",attrs:{"rounded":"","tile":"","flat":""}},[[_c('v-card-title',{class:_vm.textSize + ' progressActive--text pb-0 pt-2'},[_vm._v(" Preview ")]),_c('v-card-text',{staticClass:"pt-0"},[_c('v-img',{attrs:{"src":_vm.preview.src,"width":"100px","height":"100px","contain":""}}),_c('v-spacer')],1)],_c('v-card-actions',{staticClass:"py-0"},[_c('v-file-input',{attrs:{"dark":"","loading":_vm.loading,"rules":_vm.rules,"chips":"","show-size":"","counter":"","label":"Choose Profile Photo","accept":"image/png, image/jpeg, image/gif","prepend-inner-icon":"mdi-camera","prepend-icon":""},on:{"change":_vm.loadPhoto},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),_c('v-card-actions',{staticClass:"py-0"},[_c('v-spacer'),_c('cropper',{ref:"cropper",staticClass:"cropper",attrs:{"backgroundClass":"cropperBackground","foregroundClass":"cropperForeground","auto-zoom":true,"src":this.image.src,"stencil-props":{
          handlers: {},
          movable: true,
          scalable: true,
          aspectRatio: 1
        },"resize-image":{
          adjustStencil: true
        },"stencil-size":{
          width: 200,
          height: 200
        },"default-position":{
          top: 50
        },"default-size":_vm.defaultSize,"image-restriction":"stencil"},on:{"change":_vm.adjustCanvas}}),(_vm.file != null)?_c('ul',{staticStyle:{"list-style-type":"none"}},[_c('li',[_c('v-icon',{attrs:{"color":"silver","large":""},on:{"click":function($event){return _vm.zoom(2)}}},[_vm._v(" mdi-magnify-plus ")])],1),_c('li',[_c('v-icon',{attrs:{"color":"silver","large":""},on:{"click":function($event){return _vm.zoom(0.5)}}},[_vm._v(" mdi-magnify-minus ")])],1)]):_vm._e()],1),_c('v-spacer'),_c('v-card-actions',{staticClass:"pa-0"},[_c('v-spacer'),(_vm.file != null)?_c('BaseActionButton',{staticClass:"charcoal paper--text",attrs:{"plain":"","icon":"mdi-upload","label":"Upload","dark":""},on:{"clickedThis":_vm.uploadPhoto}}):_vm._e()],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }