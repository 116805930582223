<template>
  <v-container>
    <BaseScreenHeader
      title="Add Profile Photo"
      screenInfo="PRO-003"
      width="90%"
      :showBack="true"
    />

    <BaseLoading
      class="mt-2"
      v-if="!loaded"
      label="Updating your profile picture..."
    />
    <v-card
      v-else
      rounded
      class="charcoal charcoalTile pa-2 mx-2 d-flex flex-column"
      tile
      flat
    >
      <template v-f="file != null">
        <v-card-title :class="textSize + ' progressActive--text pb-0 pt-2'">
          Preview
        </v-card-title>
        <v-card-text class="pt-0">
          <v-img :src="preview.src" width="100px" height="100px" contain />
          <v-spacer />
        </v-card-text>
      </template>
      <v-card-actions class="py-0">
        <v-file-input
          dark
          :loading="loading"
          :rules="rules"
          chips
          show-size
          counter
          label="Choose Profile Photo"
          accept="image/png, image/jpeg, image/gif"
          prepend-inner-icon="mdi-camera"
          prepend-icon=""
          @change="loadPhoto"
          v-model="file"
        />
      </v-card-actions>
      <v-card-actions class="py-0">
        <v-spacer />
        <cropper
          class="cropper"
          ref="cropper"
          backgroundClass="cropperBackground"
          foregroundClass="cropperForeground"
          :auto-zoom="true"
          :src="this.image.src"
          :stencil-props="{
            handlers: {},
            movable: true,
            scalable: true,
            aspectRatio: 1
          }"
          :resize-image="{
            adjustStencil: true
          }"
          :stencil-size="{
            width: 200,
            height: 200
          }"
          :default-position="{
            top: 50
          }"
          :default-size="defaultSize"
          image-restriction="stencil"
          @change="adjustCanvas"
        />
        <ul v-if="file != null" style="list-style-type:none;">
          <li>
            <v-icon color="silver" large @click="zoom(2)">
              mdi-magnify-plus
            </v-icon>
          </li>
          <li>
            <v-icon color="silver" large @click="zoom(0.5)">
              mdi-magnify-minus
            </v-icon>
          </li>
        </ul>
      </v-card-actions>
      <v-spacer />
      <v-card-actions class="pa-0">
        <v-spacer />
        <BaseActionButton
          v-if="file != null"
          plain
          class="charcoal paper--text"
          icon="mdi-upload"
          label="Upload"
          dark
          @clickedThis="uploadPhoto"
        />
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
export default {
  name: 'AddPhoto',
  components: {
    Cropper
  },
  mixins: [util],
  props: {
    show: {
      type: Boolean
    }
  },
  data() {
    return {
      rules: [
        value =>
          !value ||
          value.size < 5500000 ||
          'Profile photo size should be less than 5.5 MB!'
      ],
      file: null,
      image: { src: '' },
      loaded: true,
      profile: {},
      preview: { src: '' },
      newImage: null,
      newImageSize: 0,
      loading: false,
      width: 0,
      height: 0,
      coordinates: null,
      canvas: null
    }
  },
  beforeMount() {},
  computed: {
    ...appConfig
  },
  methods: {
    boundaries({ cropper }) {
      return {
        width: cropper.clientWidth,
        height: cropper.clientHeight
      }
    },
    updateSize({ coordinates }) {
      this.size.width = Math.round(coordinates.width)
      this.size.height = Math.round(coordinates.height)
    },
    zoom(factor) {
      this.$refs.cropper.zoom(factor)
    },
    adjustCanvas({ coordinates, canvas }) {
      this.coordinates = coordinates
      let canvasLocal = canvas
      this.preview.src = canvasLocal.toDataURL('image/png')
      let t = this
      canvasLocal.toBlob(
        blob => {
          t.newImageSize = blob.size
          t.newImage = blob
        },
        'image/jpeg',
        0.9
      )
    },
    defaultSize() {
      return {
        width: 1440,
        height: 1440
      }
    },
    loadPhoto() {
      this.loading = 'progressActive'
      this.image = new Image()
      this.image.src = URL.createObjectURL(this.file)
      this.image.onload = function() {}
      this.image.onerror = function() {
        alert('Not a valid image file: ' + this.file.type)
      }
      this.loading = false
    },
    uploadPhoto() {
      /*
        for( var i = 0; i < this.files.length; i++ ){
          let file = this.files[i];
          formData.append('files[' + i + ']', file);
        }
      */
      this.loaded = false
      let formData = new FormData()
      formData.append('file', this.newImage, this.file.name)
      return axios
        .post(this.baseURL + '/user/profile/photo', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          if (response.status == 200) {
            this.loaded = true
            this.toast('Profile Photo Uploaded ..')
            this.$router.push('/profile')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
<style>
.cropper {
  width: 250px;
  height: 250px;
}
.cropperBackground {
  background-color: #212b31;
}
.cropperForeground {
  background-color: #212b31;
}
</style>
